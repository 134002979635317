@import 'variables/colors';
@import 'variables/variables';

.text-light-normal {
  font-style: normal;
  font-weight: 300;
}

.text-light-italic {
  font-style: italic;
  font-weight: 300;
}

.text-medium-normal {
  font-style: normal;
  font-weight: 500;
}

.text-bold-normal {
  font-style: normal;
  font-weight: 700;
}

.text-xsmall {
  font-size: 14px;
}

.text-small {
  font-size: 16px;
}

.text-medium {
  font-size: 20px;
}

.text-big {
  font-size: 24px;
}

.text-xbig {
  font-size: 32px;
}

.text-error {
  color: $error-primary;
  line-height: 17px;
  margin-bottom: 0;
}

.text-color-primary {
  color: $action-primary;
}

.text-color-on {
  color: $action-primary-on;
}

.text-color-tertiary {
  color: $action-tertiary;
}

.text-color-gray {
  color: $gray;
}

.text-color-dark-gray {
  color: $dark-gray;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.text-underline {
  text-decoration: underline;
}

.text-align-justify {
  text-align: justify;
}

@media screen and (max-width: $small-device) {
  .text-medium {
    font-size: 16px;
  }

  .text-big,
  .text-xbig {
    font-size: 20px;
  }
}
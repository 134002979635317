// Date Picker

.ant-picker-cell-selected .ant-picker-cell-inner {
  /* stylelint-disable-next-line declaration-no-important */
  background: $action-primary !important;
}

.ant-picker-super-prev-icon,
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  color: $action-primary;
}

.ant-picker-header-view {
  .ant-picker-month-btn:hover,
  .ant-picker-year-btn:hover {
    color: rgba($action-primary, 0.3);
  }
  color: $action-primary;
}

.ant-picker-footer {
  display: none;
}

.ant-picker-suffix {
  .anticon {
    font-size: 19px;
  }
}

// Checkbox

.ant-checkbox-wrapper {
  /* stylelint-disable-next-line declaration-no-important */
  margin-left: 0 !important;

  &:hover {
    .ant-checkbox-inner {
      /* stylelint-disable-next-line declaration-no-important */
      border-color: $action-primary !important;
    }
  }
}

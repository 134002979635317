@import '~wolox-equalizer/equalizer';

@import 'layout';

@import 'margins';

@import 'components';

@import 'fonts';

@import 'variables/fonts';

@import 'antd';

* {
  font-family: $app-font;
}

body {
  margin: 0;
  min-height: 100vh;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.root {
  display: flex;
  min-height: 100vh;
}

html,
body * {
  text-size-adjust: none;
}

// HEX
$white: #FFF;
$black: #000;

$action-primary: #1226AA;
$action-primary-on: #006AD3;
$action-secondary: #26C3FF;
$action-tertiary: #49A1C9;
$action-quaternary: #F5F7FB;

$success-primary: #57C000;
$success-primary-on: #3DC48D;
$success-secondary: #EBF3E3;

$warning-primary: #F2BE2F;
$warning-secondary: #FAF3D1;

$error-primary: #E94C4C;
$error-secondary: #F5E4E4;

$danger: #CF4628;

$light-gray: #F1F1F1;
$gray: #B0AEAE;
$medium-gray: #777;
$dark-gray: #424242;

$background-primary: $white;
$background-secondary: $light-gray;
$background-tertiary: #EEF6FF;
$background-step-error: #F2F5FF;

// RGBA
$black-transparent: rgba(0, 0, 0, 0.5);
$white-transparent: rgba(255, 255, 255, 0);
$transparent: rgba(0, 0, 0, 0);

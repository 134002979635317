@import 'variables/colors';
@import 'variables/variables';
@import 'fonts';

.unlabeled-field {
  margin-top: 30px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.full-height {
  height: 100%;
}

%input-border {
  border-radius: 2px;
  border: 1px solid $light-gray;
}

%input-error {

  @extend %input-border;
  border-color: $error-primary;
}

%input-focus {

  @extend %input-border;
  border-color: $action-secondary;
  box-shadow: 0 0 4px 0 $action-secondary;
}

%input-focus-secondary {

  @extend %input-border;
  border-color: $action-tertiary;
  box-shadow: 0 0 4px 0 $action-tertiary;
}

%input-base {

  @extend %input-border;
  background-color: $white;
  border-color: $gray;
}

%xsmall-text {

  @extend .text-xsmall;
  line-height: 17px;
}

%text-small {

  @extend .text-small;
  line-height: 20px;
}

%medium-text {

  @extend .text-small;
  line-height: 19px;
}

%large-text {

  @extend .text-medium;
  line-height: 24px;

  @media screen and (max-width: $small-device) {
    font-size: 16px;
    line-height: 19px;
  }
}

%big-text {

  @extend .text-big;
  line-height: 28px;
}

%xbig-text {

  @extend .text-xbig;
  line-height: 38px;

  @media screen and (max-width: $small-device) {
    font-size: 20px;
    line-height: 24px;
  }
}

.large-input-group {
  max-width: 380px;
  width: 100%;
}

.medium-input-group {
  max-width: 220px;
  width: 100%;
}

.padding-0{
  padding: 0 !important;
}

.input-container {

  @extend %input-base;

  &:hover {

    @extend %input-focus;
  }

  &.error {

    @extend %input-error;
  }

  &.focused {

    @extend %input-focus;
  }

  &__medium {
    padding: 10px 15px 10px 20px;
  }

  &__large {
    padding: 23px 20px;
  }
}

%input-properties {

  @extend .text-light-normal;
  background-color: transparent;
  color: $action-primary;
  z-index: 1;

  &::placeholder {
    color: $gray;
  }
}

.border-separator {
  border-bottom: 1px solid $light-gray;
}

.max-width-container {
  max-width: $max-width;
  padding: 40px 20px 0;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.position-initial {
  position: initial !important;
}


.attached-field-properties {
  @media screen and (max-width: $small-device) {
    background-color: $action-quaternary;
    border-top: 2px solid $action-secondary;
    content: '';
    height: 100%;
    left: -16px;
    position: absolute;
    top: 0;
    width: calc(100% + 32px);
    z-index: -1;
  }
}

// Modal trick to avoid scroll behind the modal

.modal-open {
  overflow-y: hidden;
}

.attached-field-down {
  border-top: 2px solid $action-secondary;
  padding: 48px 0;
  gap: 16px;
}

@media screen and (max-width: $small-device) {
  .max-width-container {
    max-width: unset;
    padding: 24px 0 0;
    width: 100%;
  }

  .large-input-group {
    max-width: 100%;
  }

  .medium-input-group {
    max-width: 100%;
  }

  .border-separator {
    border: none;
    position: relative;

    &::before {
      border-bottom: 1px solid $light-gray;
      bottom: 0;
      content: '';
      left: -16px;
      position: absolute;
      width: calc(100% + 32px);
      z-index: -1;
    }
  }

  .attached-field-down {
    border: initial;
    padding: 24px 0;

    &--treatments{
      border-top: 2px solid $action-secondary;
      padding: 24px 0;
      margin-bottom: 0;
    }
  }
}

.anchor-autogestion{
  justify-content: start !important;
  margin-bottom: 48px; 
}

.link-autogestion-f2{
  font-weight: 500 !important;
}

// General
$max-width: 980px;
$footer-height: 64px;
$footer-height-mobile: 118px;
$modal-z-index: 4;

// Media queries
$extra-small-device: 320px;
$extra-small-device-plus: 414px;
$small-device: 550px;
$small-device-landscape: 667px;
$medium-device: 768px;
$desktop-device: 991px;

// Transitions
$medium-transition-duration: 250ms;
$quick-transition-duration: 200ms;
$transition-duration: 290ms;
$transition-function: cubic-bezier(0.79, 0.01, 0.38, 0.99);
